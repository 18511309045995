import React, { useEffect, useMemo, useState } from 'react';
import {
	Modal,
	Group,
	Stack,
	Text,
	Button,
	Select,
	Paper,
	ScrollArea,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import ModalTitle from '../../../../../../components/Titles/ModalTitle';
import {
	ANYONE_WITH_LINK,
	INVITE_ONLY,
} from '../../../../../../helpers/settings/settings.constants';
import UserList from '../../../../../../components/UserList';
import { UsersMultiSelect } from '../../../../../../components/UsersMultiSelect';
import type {
	VideoClip,
	VideoClipUserLink,
} from '../../../../../../Atoms/clips';
import { useRecoilValue } from 'recoil';
import {
	teamDataAtom,
	userOrganizationMembersMap,
} from '../../../../../../Atoms/userAtoms';
import type { InvitedUser } from '../../../../../../interfaces/user';

interface ShareClipModalProps {
	opened: boolean;
	onClose: () => void;
	selectedClip: VideoClip;
	handleRemoveUser: (userId: string) => Promise<void>;
	organizer: any;
	sharingEnabled: boolean;
	handleChangeSharingOption: (value: string) => void;
	handleAddUsers: () => void;
	buttonLoading: boolean;
	form: any;
	isClipOwner: boolean;
}

export function ShareClipModal({
	opened,
	onClose,
	selectedClip,
	handleRemoveUser,
	organizer,
	sharingEnabled,
	handleChangeSharingOption,
	handleAddUsers,
	buttonLoading,
	form,
	isClipOwner,
}: ShareClipModalProps) {
	const orgUserMap = useRecoilValue(userOrganizationMembersMap);
	const teamData = useRecoilValue(teamDataAtom);
	const [data, setData] = useState<InvitedUser[]>([]);

	const userListWithOwnerFirst = useMemo(() => {
		return [...(selectedClip?.videoClipUserLinks || [])]
			.sort((a, b) => {
				if (selectedClip.owningUserID === a.userID) return -1;
				if (selectedClip.owningUserID === b.userID) return 1;
				return (
					new Date(a.createdAt || 0).getTime() -
					new Date(b.createdAt || 0).getTime()
				);
			})
			.map((link: VideoClipUserLink): any => {
				const user = orgUserMap.get(link.userID) || {};
				if (user && typeof user === 'object' && Object.keys(user).length > 0) {
					return {
						id: link.userID,
						email: user.email || '',
						firstName: user.firstName || '',
						lastName: user.lastName || '',
						userlink: link as any,
					};
				}

				return link;
			});
	}, [
		selectedClip?.videoClipUserLinks,
		selectedClip?.owningUserID,
		orgUserMap,
	]);

	useEffect(() => {
		const userIDs = new Set(userListWithOwnerFirst.map((user) => user.id));
		const filteredTeamData = [...teamData].filter(
			(user) => !userIDs.has(user.id)
		);
		setData(filteredTeamData);
	}, [teamData, userListWithOwnerFirst]);

	const sharingOption = selectedClip?.sharingToken?.active
		? ANYONE_WITH_LINK
		: INVITE_ONLY;

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			title={<ModalTitle text={`Share ${selectedClip?.title}`} />}
			size='md'
		>
			<Stack spacing='md'>
				{isClipOwner && (
					<Group position='apart' noWrap>
						<Text size='sm' weight={600}>
							People with access
						</Text>
						<Select
							radius='xl'
							value={sharingOption}
							disabled={!sharingEnabled}
							onChange={handleChangeSharingOption}
							data={[
								{ label: 'Invite Only', value: INVITE_ONLY },
								{ label: 'Anyone With Link', value: ANYONE_WITH_LINK },
							]}
							placeholder='Select Sharing Option'
						/>
					</Group>
				)}
				<Paper
					p='md'
					withBorder
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '300px',
					}}
				>
					<ScrollArea style={{ flex: 1 }}>
						<UserList
							searchValue=''
							users={userListWithOwnerFirst}
							gridColSpan={12}
							emptyText='No users have access to this clip.'
							handleRemoveUserByID={handleRemoveUser}
							showLinkedIn={false}
							organizer={organizer}
							owningUserID={selectedClip?.owningUserID}
						/>
					</ScrollArea>
				</Paper>
				{isClipOwner && (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							if (form.validate().hasErrors) return;
							handleAddUsers();
						}}
					>
						<Stack spacing='md'>
							<UsersMultiSelect
								creatable
								descriptionText='Add Viewers'
								variantType='filled'
								data={data}
								setData={setData}
								searchable
								labelText=''
								placeholder='Add a name, email or team.'
								name='viewers'
								form={form}
								icon={<FontAwesomeIcon icon={faUser} />}
							/>
							<Button
								type='submit'
								loading={buttonLoading}
								leftIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
							>
								Share
							</Button>
						</Stack>
					</form>
				)}
			</Stack>
		</Modal>
	);
}
