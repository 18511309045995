import React, { useEffect, useState } from 'react';
import {
	Modal,
	Button,
	Text,
	Stack,
	createStyles,
	Group,
	Checkbox,
	Textarea,
	Select,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faShare,
	faUser,
	faPaperPlaneTop,
	faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';
import { useForm } from '@mantine/form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { teamDataAtom } from '../../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import ModalTitle from '../../Titles/ModalTitle';
import { logger } from '../../../helpers/logger';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import {
	INVITE_ONLY,
	ANYONE_WITH_LINK,
} from '../../../helpers/settings/settings.constants';

const useStyles = createStyles((theme) => ({
	shareButton: {
		height: 40,
		borderRadius: theme.radius.md,
		padding: '0 24px',
		backgroundColor: theme.fn.primaryColor(),
		color: theme.white,
		fontWeight: 500,
		'&:hover': {
			backgroundColor: theme.fn.primaryColor(),
		},
	},
}));

interface ShareWithOthersModalProps {
	opened: boolean;
	close: () => void;
	onShare: ({ emails, message, sharingOption }) => void;
}

export function ShareWithOthersModal({
	opened,
	close,
	onShare,
}: ShareWithOthersModalProps) {
	const { classes } = useStyles();
	const teamData = useRecoilValue(teamDataAtom);
	const [notifyPeopleChecked, setNotifyPeopleChecked] = useState(false);
	const [message, setMessage] = useState('');
	const [sharingOption, setSharingOption] = useState(INVITE_ONLY);
	const [buttonLoading, setButtonLoading] = useState(false);

	const form = useForm({
		initialValues: {
			viewers: [],
		},
		validate: {
			viewers: (value) =>
				value.length ? null : 'At least one viewer is required.',
		},
	});

	const handleShare = async () => {
		if (form.values.viewers.length > 0) {
			try {
				setButtonLoading(true);
				await onShare({
					emails: form.values.viewers,
					message,
					sharingOption,
				});
				form.reset();
				close();
			} catch (err) {
				console.log('handleShare -> err', err);
				logger('error', 'error adding users to clip', err);
			} finally {
				setButtonLoading(false);
			}
		}
	};

	useEffect(() => {
		if (!opened) {
			form.reset();
			setMessage('');
			setSharingOption(INVITE_ONLY);
			setNotifyPeopleChecked(false);
		}
	}, [opened]);

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={<ModalTitle text='Share Video Clip' />}
			size='md'
			radius='md'
			zIndex={10000}
		>
			<Stack spacing='md'>
				<UsersMultiSelect
					creatable={true}
					descriptionText={'Add Viewers'}
					variantType={'filled'}
					data={teamData}
					searchable={true}
					labelText={''}
					placeholder='Add a name, email or team.'
					name={'viewers'}
					form={form}
					icon={<FontAwesomeIcon icon={faUser} />}
				/>

				<Group position='apart' align='center'>
					<Checkbox
						checked={notifyPeopleChecked}
						onChange={(event) =>
							setNotifyPeopleChecked(event.currentTarget.checked)
						}
						label='Add a message'
					/>
				</Group>

				{notifyPeopleChecked && (
					<Textarea
						placeholder='Add a custom message to the notification email'
						minRows={3}
						value={message}
						onChange={(event) => setMessage(event.currentTarget.value)}
					/>
				)}

				<Select
					label='Sharing Option'
					radius={'xl'}
					w={'180px'}
					value={sharingOption}
					onChange={(value) => setSharingOption(value)}
					data={[
						{ label: 'Invite Only', value: INVITE_ONLY },
						{ label: 'Anyone With Link', value: ANYONE_WITH_LINK },
					]}
					placeholder='Select Sharing Option'
					styles={{
						label: {
							marginBottom: '7px',
						},
					}}
				/>

				<Group position='right'>
					<Button variant='outline' onClick={close}>
						Skip
					</Button>
					<Button
						loading={buttonLoading}
						onClick={handleShare}
						className={classes.shareButton}
						leftIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
					>
						Share
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
