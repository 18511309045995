import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	container: {
		backgroundColor: theme.colors.gray[0],
		borderRadius: theme.radius.lg,
		marginTop: theme.spacing.xl,
	},
	summary: {
		fontSize: '0.875rem',
		color: theme.colors.gray[9],
	},
}));

interface ClipSummaryProps {
	summary: string;
}

export function ClipSummary({ summary }: ClipSummaryProps) {
	const { classes } = useStyles();

	return (
		<div className={classes.container}>
			<p className={classes.summary}>{summary}</p>
		</div>
	);
}
