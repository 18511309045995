import React from 'react';
import {
	ActionIcon,
	Group,
	Menu,
	Paper,
	Text,
	Button,
	createStyles,
	Tooltip,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEllipsisVertical,
	faPlay,
	faEye,
	faShare,
	faPencil,
	faUserGroup,
	faLink,
	faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { VideoClip } from '../../../../../../Atoms/clips';

const useStyles = createStyles((theme) => ({
	clipCard: {
		padding: theme.spacing.md,
		marginBottom: theme.spacing.sm,
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
		transition: 'all 0.2s ease',
		borderRadius: theme.radius.md,
	},
}));

interface ClipItemProps {
	clip: VideoClip;
	onPlay: (clip: any) => void;
	onQuickView: (clip: any) => void;
	onShare: (clip: any) => void;
	onEdit: (clip: any) => void;
	onManageAccess: (clip: any) => void;
	onCopyLink: (clip: any) => void;
	onDelete: (clipId: string) => void;
	currentUserId: string;
}

export function ClipItem({
	clip,
	onPlay,
	onQuickView,
	onShare,
	onEdit,
	onManageAccess,
	onCopyLink,
	onDelete,
	currentUserId,
}: ClipItemProps) {
	const { classes, theme } = useStyles();

	const formatTimeRange = (startTime: number, endTime: number) => {
		const formatTime = (time: number) => {
			const minutes = Math.floor(time / 60);
			const seconds = Math.floor(time % 60);
			return `${minutes}:${seconds.toString().padStart(2, '0')}`;
		};
		return `${formatTime(startTime)} - ${formatTime(endTime)}`;
	};

	const isOwner = clip.owningUserID === currentUserId;

	return (
		<Paper className={classes.clipCard} shadow='sm'>
			<Group position='apart' noWrap>
				<Group spacing='xs' noWrap style={{ flex: 1 }}>
					<div style={{ flex: 1 }}>
						<Text weight={600} size='sm' lineClamp={1}>
							{clip.title}
						</Text>
						<Text size='xs' color='dimmed'>
							{formatTimeRange(clip.startTime, clip.endTime)}
						</Text>
					</div>
					{!isOwner && (
						<Text size='xs' color='dimmed'>
							Owned by {clip.owningUser?.firstName} {clip.owningUser?.lastName}
						</Text>
					)}
				</Group>
				<Group spacing={0} noWrap>
					<Tooltip label='View' position='top'>
						<ActionIcon variant='transparent' onClick={() => onQuickView(clip)}>
							<FontAwesomeIcon icon={faEye} />
						</ActionIcon>
					</Tooltip>
					{isOwner ? (
						<Tooltip label='Share' position='top'>
							<ActionIcon variant='transparent' onClick={() => onShare(clip)}>
								<FontAwesomeIcon icon={faShare} />
							</ActionIcon>
						</Tooltip>
					) : (
						<Tooltip label='Copy link' position='top'>
							<ActionIcon
								variant='transparent'
								onClick={() => onCopyLink(clip)}
							>
								<FontAwesomeIcon icon={faLink} />
							</ActionIcon>
						</Tooltip>
					)}

					<Menu shadow='md' width={200} position='bottom-end'>
						<Menu.Target>
							<ActionIcon variant='transparent' w={14} miw={14}>
								<FontAwesomeIcon icon={faEllipsisVertical} />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							{isOwner && (
								<>
									<Menu.Item
										icon={<FontAwesomeIcon icon={faPencil} />}
										onClick={() => onEdit(clip)}
									>
										Edit clip
									</Menu.Item>
									<Menu.Item
										icon={<FontAwesomeIcon icon={faUserGroup} />}
										onClick={() => onManageAccess(clip)}
									>
										Manage access
									</Menu.Item>
									<Menu.Item
										icon={<FontAwesomeIcon icon={faLink} />}
										onClick={() => onCopyLink(clip)}
									>
										Copy link
									</Menu.Item>
								</>
							)}

							<Menu.Item
								icon={<FontAwesomeIcon icon={faTrash} />}
								onClick={() => onDelete(clip.id)}
								color='red'
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
			</Group>
		</Paper>
	);
}
