import { encode } from 'js-base64';
import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

interface GetVideoClipsParams {
	createdAt?: string;
	search?: string;
	limit?: number;
	offset?: number;
	order?: string;
	userID?: string;
	collectionID?: string;
	meetingID?: string;
	organizationID: string;
}

export const getVideoClips = async ({
	createdAt,
	organizationID,
	userID,
	search = '',
	limit = 20,
	offset = 0,
	collectionID,
	order = '-createdAt',
	meetingID,
}: GetVideoClipsParams) => {
	logger('info', 'Request sent to getVideoClips', { organizationID });

	try {
		const response = await axiosInstance.post(
			'v1/clips',
			{
				createdAt,
				organizationID,
				userID,
				search: encode(search || ''),
				offset,
				collectionID,
				order,
				meetingID,
			},
			{
				headers: { 'x-organization-id': organizationID },
			}
		);
		return response.data;
	} catch (error) {
		logger('error', 'Error fetching video clips', { error });
		throw error;
	}
};

export const createVideoClip = async (
	payload: {
		meetingID: string;
		startTime: number;
		endTime: number;
		title?: string;
		thumbnailTime?: number;
	},
	organizationID: string
) => {
	return axiosInstance
		.put('v1/meeting-video-clips', payload, {
			headers: {
				'x-organization-id': organizationID,
			},
		})
		.then((res) => {
			logger('info', 'Video clip created', res);
			return res?.data?.data;
		})
		.catch((err) => {
			logger('error', 'Error creating video clip', err);
			throw new Error(err?.response?.data || 'Error creating video clip');
		});
};

export const generateClipSummary = async (
	clipId: string,
	organizationID: string
) => {
	return axiosInstance
		.post(
			'v1/meeting-video-clips/generate-summary',
			{
				meetingVideoClipID: clipId,
			},
			{
				headers: {
					'x-organization-id': organizationID,
				},
			}
		)
		.then((res) => {
			logger('info', 'Clip summary generated', res);
			return res?.data?.data;
		})
		.catch((err) => {
			logger('error', 'Error generating clip summary', err);
			throw new Error(err?.response?.data || 'Error generating clip summary');
		});
};

export const setClipThumbnail = async (
	clipId: string,
	thumbnailUrl: string,
	organizationID: string
) => {
	const payload = { meetingVideoClipID: clipId, thumbnailUrl };
	return axiosInstance
		.post('v1/meeting-video-clips/set-thumbnail', payload, {
			headers: {
				'x-organization-id': organizationID,
			},
		})
		.then((res) => {
			logger('info', 'Clip thumbnail set', res);
			return res.data;
		})
		.catch((err) => {
			logger('error', 'Error setting clip thumbnail', err);
			throw new Error(err?.response?.data || 'Error setting clip thumbnail');
		});
};

export const updateVideoClip = async (
	clipId: string,
	updateData: Partial<{
		startTime: number;
		endTime: number;
		title: string;
		summary: string;
	}>,
	organizationID: string
) => {
	return axiosInstance
		.patch(`v1/meeting-video-clip/${clipId}`, updateData, {
			headers: {
				'x-organization-id': organizationID,
			},
		})
		.then((res) => {
			logger('info', 'Video clip updated', res);
			return res?.data?.data;
		})
		.catch((err) => {
			logger('error', 'Error updating video clip', err);
			throw new Error(err?.response?.data || 'Error updating video clip');
		});
};

export const getVideoClip = async (clipId: string, organizationID: string) => {
	return axiosInstance
		.get(`v1/meeting-video-clip/${clipId}`, {
			headers: {
				'x-organization-id': organizationID,
			},
		})
		.then((res) => {
			logger('info', 'Video clip retrieved', res);
			return res.data;
		})
		.catch((err) => {
			logger('error', 'Error retrieving video clip', err);
			throw new Error(err?.response?.data || 'Error retrieving video clip');
		});
};

export const destroyVideoClips = async (
	clipIds: string[],
	organizationID: string
) => {
	return axiosInstance
		.delete('v1/meeting-video-clips', {
			data: { meetingVideoClipIDs: clipIds },
			headers: {
				'x-organization-id': organizationID,
			},
		})
		.then((res) => {
			logger('info', 'Video clips destroyed', res);
			return res.data;
		})
		.catch((err) => {
			logger('error', 'Error destroying video clips', err);
			throw new Error(err?.response?.data || 'Error destroying video clips');
		});
};

export const addUsersToClip = async ({
	emails,
	videoClipID,
	organizationID,
	message,
	sharingOption,
}: {
	emails: string[];
	videoClipID: string;
	organizationID: string;
	message?: string;
	sharingOption?: string;
}) => {
	const payload = {
		emails,
		videoClipID,
		organizationID,
		message,
		sharingOption,
	};

	return axiosInstance
		.post('v1/meeting-video-clips/invite-users', payload, {
			headers: {
				'x-organization-id': organizationID,
			},
		})
		.then((res) => {
			logger('info', 'Users invited to video clip', res);
			return res.data;
		})
		.catch((err) => {
			logger('error', 'Error inviting users to video clip', err);
			throw new Error(
				err?.response?.data || 'Error inviting users to video clip'
			);
		});
};

export const removeUsersFromClip = async (
	userLinkIDs: string[],
	videoClipID: string,
	organizationID: string
) => {
	const payload = { userLinkIDs, videoClipID, organizationID };

	return axiosInstance
		.delete('v1/meeting-video-clips/remove-users', {
			data: payload,
			headers: {
				'x-organization-id': organizationID,
			},
		})
		.then((res) => {
			logger('info', 'Users removed from video clip', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error removing users from video clip', err);
			throw new Error(
				err?.response?.data || 'Error removing users from video clip'
			);
		});
};
