import {
	Button,
	CopyButton,
	Divider,
	Group,
	Modal,
	Stack,
	Text,
	createStyles,
} from '@mantine/core';
import ModalTitle from '../../Titles/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLink,
	faPaperPlaneTop,
	faUser,
} from '@fortawesome/pro-light-svg-icons';
import { IMeeting } from '../../../interfaces/meeting';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import { useForm } from '@mantine/form';
import { flattenValidateAndFilterInvitees } from '../../../helpers/teams';
import {
	getUsersByOrg,
	removeUsersFromAMeeting,
	sendMinutesEmail,
} from '../../../api/api';
import {
	User,
	currentUser,
	currentUserFormattedTeams,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { logger } from '../../../helpers/logger';
import {
	currentMeetingDistributionList,
	meetingAttachments,
} from '../../../Atoms/meetingAtom';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import UserList from '../../UserList';
import { faClock, faFolderArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { downloadAttachment } from '../../../helpers/attachments';
import { faUpFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { teamDataAtom } from '../../../Atoms/userAtoms';

const useStyles = createStyles(() => ({
	dateInput: {
		borderRadius: '8px',
		fontSize: '12px',
		width: '120px',
		height: '40px',
	},
	actionButton: {
		cursor: 'pointer',
	},
}));

interface Props {
	opened: boolean;
	close: () => void;
	meeting: IMeeting;
	isMinutesPage?: boolean;
}

export default function MeetingMinutesModal({
	close,
	opened,
	meeting,
	isMinutesPage = false,
}: Props) {
	const { classes, theme } = useStyles();
	const { organizationID, id } = meeting;
	const user = useRecoilValue(currentUser);
	const orgMembers = useRecoilValue(userOrganizationMembers);
	const [distributionList, setDistributionList] = useRecoilState(
		currentMeetingDistributionList
	);
	const teamData = useRecoilValue(teamDataAtom);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [notifyPeopleChecked, setNotifyPeopleChecked] = useState(false);
	const attachments = useRecoilValue(meetingAttachments);
	const minutesDoc = isMinutesPage
		? meeting['attachments'][0]
		: attachments.find(
				(attachment) =>
					attachment.type === 'minutes' ||
					attachment.fileName.includes('Minutes')
		  );

	const form = useForm({
		initialValues: {
			viewers: [],
		},
		validate: {
			viewers: (value) =>
				value.length ? null : 'At least one viewer is required.',
		},
	});

	const userData = orgMembers
		.map((user: User) => ({
			...user,
			label: user.email,
			value: user.email,
			key: user.id,
		}))
		.filter((item: User, index, array) => {
			return (
				user.id !== item.id &&
				array.findIndex((i) => i.id === item.id) === index
			);
		});

	useEffect(() => {
		if (!form.values.viewers.length) setNotifyPeopleChecked(false);
	}, [form.values.viewers]);

	const send = async () => {
		try {
			setButtonLoading(true);
			const emails = flattenValidateAndFilterInvitees(form.values.viewers);

			const res = await sendMinutesEmail({
				meetingID: id,
				organizationID,
				emails,
				attachmentID: minutesDoc?.id,
			});

			logger('info', 'send meeting minutes email', res);
			showSuccessNotification({
				title: 'Success!',
				message: 'Meeting minutes email sent successfully.',
			});
			form.reset();
			close();
		} catch (error: unknown) {
			logger('error', 'failed to invite users to meeting', error);
			showFailureNotification({
				message: 'Sorry, failed to invite users to meeting. Please try again.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	const handleRemoveUser = async (email: string) => {
		if (!email) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to remove the user from the meeting. Please give it another try.',
			});
			return;
		}
		try {
			logger('info', 'remove user from meeting', {
				meetingID: id,
				organizationID,
				email,
			});
			const res = await removeUsersFromAMeeting([email], id, organizationID);
			const newInvitedUsers = res?.data?.data?.invitedUsers || [];
			setDistributionList(newInvitedUsers);
			showSuccessNotification({
				message: 'User successfully removed from the meeting.',
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to remove the user from the meeting. Please give it another try.',
			});
			logger('error', 'error removing user from meeting:', {
				meetingID: id,
				organizationID,
				email,
				error,
			});
		}
	};

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={
				<ModalTitle
					text={'Meeting Minutes'}
					icon={<FontAwesomeIcon icon={faClock} size='xl' />}
				/>
			}
			size={'lg'}
			zIndex={10000}
			radius={'md'}
			// withinPortal={false}
			styles={(theme) => ({
				header: {
					borderRadius: theme.spacing.lg,
				},
			})}
		>
			<Stack mt={'md'}>
				<Group
					noWrap
					spacing={'xs'}
					align='center'
					className={classes.actionButton}
					onClick={(e) => {
						e.stopPropagation();
						minutesDoc
							? downloadAttachment(minutesDoc, meeting.organizationID)
							: showFailureNotification({
									message: 'Sorry, no meeting minutes available for download.',
							  });
					}}
				>
					<FontAwesomeIcon
						icon={faFolderArrowDown}
						size='lg'
						color={theme.colors['primary-cta'][0]}
						style={{
							position: 'relative',
							bottom: '2px',
						}}
					/>
					<Text size={14} color='primary-cta'>
						Download Meeting Minutes
					</Text>
				</Group>
				<Divider />
				<Group noWrap position='apart' spacing={'xs'}>
					<Group noWrap>
						<FontAwesomeIcon icon={faUpFromBracket} />
						<Text color='primary-text' size={14}>
							Share Meeting Minutes
						</Text>
					</Group>
				</Group>
				<UsersMultiSelect
					// autoFocus={true}
					creatable={true}
					descriptionText={'Add Viewers'}
					variantType={'filled'}
					data={teamData}
					searchable={true}
					labelText={''}
					placeholder='Add a name, email or team.'
					name={'viewers'}
					form={form}
					icon={<FontAwesomeIcon icon={faUser} />}
				/>
				{/* <Textarea
					placeholder='Add a message'
					minRows={5}
					value={message}
					onChange={(event) => setMessage(event.currentTarget.value)}
				/> */}
				<Group noWrap position='right'>
					<Button
						radius={'md'}
						leftIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
						disabled={form.values.viewers.length === 0}
						onClick={send}
						loading={buttonLoading}
					>
						Send
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
