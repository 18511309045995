import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router';
import { Group, Pagination, ScrollArea, Text } from '@mantine/core';
import { encode } from 'js-base64';
import { useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import axios from 'axios';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import ClipsHeader from './ClipsHeader';
import { user } from '../../../Atoms/userAtoms';
import ClipsTable from './ClipsTable';
import { logger } from '../../../helpers/logger';
import {
	clipBeingAddedToCollection,
	clipsView,
	reloadClipsAtom,
} from '../../../Atoms/clips';
import ClipsCardView from './ClipsCardView';
import { getQuery } from '../../../api/api';
import { getVideoClips } from '../../../api/clips';
import { AddToCollectionModal } from '../../../components/Modals/CreateClipModals/AddToCollectionModal';
import {
	archiveBulkButtonLoadingAtom,
	collectionsAtom,
	SpecialCollections,
} from '../../../Atoms/collections';
import { useCollections } from '../../../customHooks/useCollections';

export interface ClipsProps {
	segmentValue: string;
	skip?: number;
	search?: string;
	highlightsOnly?: boolean;
	order?: string;
}

export interface NavigateParams {
	segment?: string;
	search?: string;
	skip?: number;
	limit?: number;
	order?: string;
	startDate?: number;
	endDate?: number;
}

export default function Clips(props: ClipsProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const currentUser = useRecoilValue(user);
	const [cancelTokenSource, setCancelTokenSource] = useState(
		axios.CancelToken.source()
	);
	const [reloadClips, setReloadClips] = useRecoilState(reloadClipsAtom);
	const { segmentValue, highlightsOnly, skip, search = '', order } = props;
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		null,
		null,
	]);

	const [currentOrder, setCurrentOrder] = useState('-createdAt');
	const viewport = useRef<HTMLDivElement>(null);
	const [selection, setSelection] = useState<string[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [
		sharingModalOpened,
		{ open: openSharingModal, close: closeSharingModal },
	] = useDisclosure(false);

	const [shareModalOpened, { open: openShareModal, close: closeShareModal }] =
		useDisclosure(false);
	const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
		useDisclosure(false);
	const [
		addToCollectionModalOpened,
		{ open: openAddToCollectionModal, close: closeAddToCollectionModal },
	] = useDisclosure(false);
	const [addingClip, setAddingClip] = useRecoilState(
		clipBeingAddedToCollection
	);
	const [opened, setOpened] = useState(false);
	const setArchiveBulkButtonLoading = useSetRecoilState(
		archiveBulkButtonLoadingAtom
	);
	const collections = useRecoilValue(collectionsAtom);
	const { addContentToCollection } = useCollections(false);
	const archiveCollection = collections.find(
		(c) => c.name === SpecialCollections.Archived
	);

	useEffect(() => {
		if (addingClip) {
			openAddToCollectionModal();
		}
	}, [addingClip]);

	const changePage = (value: number) => {
		navigateTo({
			skip: (value > 0 ? value - 1 : 0) * limit,
			limit,
			search: encode(search),
			order,
		});
	};

	const changeSort = (value: string) => {
		navigateTo({
			skip,
			limit,
			order: value,
			search: encode(search),
		});
	};

	const handleSearch = (value: string) => {
		setCurrentSearchValue(value);
		return navigateTo({
			search: encode(value),
		});
	};

	const navigateTo = (params: NavigateParams) => {
		const { segment, skip, limit, order, search } = params;
		const query = getQuery({ skip, limit, order, search });
		const uri = `${location.pathname}?${query}`;
		return navigate(uri);
	};

	const [isInitialized, setInitialized] = useState(false);

	useEffect(() => {
		setInitialized(true);
	}, [order]);

	useEffect(() => {
		if (!isInitialized) return;
		getClips({
			skip,
			limit,
			order,
			search,
		});
	}, [reloadClips]);

	useEffect(() => {
		return () => {
			setSelection([]);
			cancelTokenSource.cancel('Component unmounted');
			setAddingClip(null);
		};
	}, []);

	const [currentViewType, setCurrentViewType] = useState('card');
	const [currentSearchValue, setCurrentSearchValue] = useState('');

	useEffect(() => {
		if (currentViewType !== viewType) {
			setCurrentViewType(viewType);
			scrollToTop();
		}
		refresh();
	}, [skip, currentSearchValue, order, highlightsOnly, segmentValue]);

	useEffect(() => {
		if (!isInitialized) return;
		if (currentViewType !== viewType) {
			setCurrentViewType(viewType);
			scrollToTop();
		}

		if (dateRange[0] && dateRange[1]) {
			refresh();
		} else if (dateRange[0] === null && dateRange[1] === null) {
			refresh();
		}
	}, [dateRange]);

	const refresh = async (newTotal = null) => {
		try {
			const currentSkip = (page - 1) * limit;

			if (skip !== currentSkip) {
				await getClips({
					skip,
					limit,
					order,
					search,
					newTotal,
				});
				scrollToTop();
			} else if (currentSearchValue !== search) {
				setCurrentSearchValue(search);
				await getClips({
					skip: 0,
					search,
					newTotal,
				});
				scrollToTop();
			} else if (currentOrder !== order) {
				setCurrentOrder(order);
				await getClips({
					skip,
					limit,
					order,
					search,
					newTotal,
				});
				scrollToTop();
			} else {
				await getClips({
					skip,
					limit,
					order,
					search,
					newTotal,
				});
				scrollToTop();
			}
		} catch (error) {
			console.error(`Clips.refresh: error:`, error);
			logger('error', 'Clips.refresh err', error);
		} finally {
			setLoading(false);
		}
	};

	const scrollToTop = () => {
		if (viewport?.current) {
			viewport.current.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(20);
	const viewType = useRecoilValue(clipsView);

	const getClips = async (params?: any) => {
		try {
			if (cancelTokenSource) {
				cancelTokenSource.cancel('New request coming in');
			}

			const newSource = axios.CancelToken.source();
			setCancelTokenSource(newSource);

			setLoading(true);
			const { searchValue = '', skip = 0, newTotal } = params || {};

			const filter = {};

			const currentOrder = order || '-createdAt';

			if (dateRange[0] && dateRange[1]) {
				filter['createdAt'] = {
					start: dateRange[0]?.getTime(),
					end: dateRange[1]?.getTime(),
				};
			}

			let offset;
			if (newTotal) {
				offset =
					newTotal % limit === 0
						? (Math.floor(newTotal / limit) - 1) * limit
						: Math.floor(newTotal / limit) * limit;
				offset = Math.min(offset, isNaN(params.skip) ? 0 : Number(params.skip));
			} else {
				offset = isNaN(params.skip) ? 0 : Number(params.skip);
			}

			const query = {
				organizationID: currentUser.currentOrganizationID,
				...filter,
				search: currentSearchValue ? currentSearchValue : search,
				limit: params.limit || 20,
				offset,
				order: currentOrder,
			};

			const searchParam = currentSearchValue
				? currentSearchValue
				: search || '';

			setSearchParams({
				skip: String(offset),
				...(searchParam.length > 0 && {
					search: encode(searchParam),
				}),
				offset,
			});

			const response = await getVideoClips(query);

			// const { count, data } = (response?.data as any) || {};
			const { meta, data } = response as any;

			const {
				count,
				limit: responseLimit,
				offset: responseOffset,
			} = meta || {};

			if (newTotal) {
				const newPage =
					newTotal % limit === 0
						? Math.floor(newTotal / limit)
						: Math.floor(newTotal / limit) + 1;
				const lowestPage = Math.min(page, newPage);
				setPage(lowestPage);
			} else {
				setPage(Math.floor(offset / limit) + 1);
			}
			if (count !== undefined) setTotal(count);
			if (data !== undefined) setData(data);
		} catch (error) {
			console.error(`Clips.getClips: error:`, error);
			logger('error', 'Clips.getClips err', error);
		} finally {
			setLoading(false);
		}
	};

	const renderContent = () => {
		if (viewType === 'card') {
			return (
				<ClipsCardView
					clips={data}
					setClips={setData}
					searchValue={search}
					loading={!isInitialized || loading}
					clipCount={total}
					cardsPerPage={limit}
					handleGetClipsData={getClips}
					segmentValue={segmentValue}
					openSharingModal={openSharingModal}
					openEditModal={openEditModal}
				/>
			);
		} else {
			return (
				<ClipsTable
					clips={data}
					setClips={setData}
					search={search}
					clipCount={total}
					rowsPerPage={limit}
					fetchingClips={loading}
					changeSort={changeSort}
					segmentValueProp={segmentValue}
					openSharingModal={openSharingModal}
				/>
			);
		}
	};

	const bulkActionDeleteClips = async () => {
		try {
			if (!selection.length) {
				showFailureNotification({
					message:
						'You must select at least one clip before performing this bulk action.',
				});
				return;
			}
			const clips = selection
				.map((id) => data.find((c) => c.id === id))
				.filter(Boolean);
			if (!clips.every((c) => c?.owningUserID === currentUser.id)) {
				showFailureNotification({
					message:
						'You can only delete clips you own. Please uncheck the clips you are not an owner of.',
				});
				return;
			}
			// Implement clip deletion logic here
			setSelection([]);
			await refresh(total - clips.length);
			setReloadClips((o) => !o);
			showSuccessNotification({
				message: `Clips were successfully deleted.`,
			});
		} catch (err) {
			logger('error', 'Error deleting clip', err);
			showFailureNotification({
				message: 'Error deleting clip. Please try again.',
			});
		}
	};

	const handleBulkAddToCollection = async (clipIDs: string[]) => {
		if (!clipIDs.length) {
			showFailureNotification({
				message:
					'You must select at least one clip before performing this bulk action.',
			});
			return;
		}
		const clipsToAdd = clipIDs
			.map((id) => data.find((c) => c.id === id))
			.filter(Boolean);
		// Implement add to collection logic here
	};

	const handleBulkArchive = async (meetingIDs: string[]) => {
		try {
			setArchiveBulkButtonLoading(true);
			if (!meetingIDs.length) {
				showFailureNotification({
					message:
						'You must select at least one meeting before performing this bulk action.',
				});
				return;
			}

			const archivedMeetings = await addContentToCollection(
				archiveCollection.id,
				meetingIDs
			);
			const archivedNumber = archivedMeetings.length;
			setSelection([]);
			await refresh(total - archivedNumber);
			showSuccessNotification({
				message: `Meetings were successfully archived.`,
			});
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meetings were not successfully archived. Please try again.`,
			});
		} finally {
			setArchiveBulkButtonLoading(false);
		}
	};

	return (
		<>
			<div id='sharing-modals'>
				{/* <SharingModal
					opened={sharingModalOpened}
					close={closeSharingModal}
					organizationID={currentUser.currentOrganizationID}
				/> */}
				<AddToCollectionModal
					opened={addToCollectionModalOpened}
					close={closeAddToCollectionModal}
					clip={addingClip}
				/>
			</div>
			<ClipsHeader
				searchValue={search}
				handleSearch={handleSearch}
				opened={opened}
				setOpened={setOpened}
				bulkActionDeleteClips={bulkActionDeleteClips}
				handleBulkAddToCollection={handleBulkAddToCollection}
				handleBulkArchive={handleBulkArchive}
				dateRange={dateRange}
				setDateRange={setDateRange}
				segmentValue={segmentValue}
			/>

			<div
				style={{
					height: 'calc(100% - 90px)',
					width: '100%',
					margin: 0,
					padding: 0,
					paddingTop: '16px',
				}}
			>
				<ScrollArea
					viewportRef={viewport}
					h={'100%'}
					w={'calc(100% + 10px)'}
					pb={'sm'}
					offsetScrollbars
					styles={(theme) => ({
						viewport: {
							height: '100%',
							position: 'relative',
						},
						scrollbar: {
							right: 0,
						},
						thumb: {
							right: 0,
						},
					})}
				>
					{renderContent()}
				</ScrollArea>
			</div>

			<Group position='right' h={32}>
				<Pagination
					withEdges
					value={page}
					onChange={changePage}
					total={Math.ceil(total / limit)}
				/>
				<Text mt={0} color='secondary-text' align={'center'} size={12}>
					{`${skip}-${skip + limit > total ? total : skip + limit} of ${total}`}
				</Text>
			</Group>
		</>
	);
}
