import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	Modal,
	Button,
	Group,
	Select,
	Stack,
	createStyles,
	Text,
	Loader,
	Center,
} from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { collectionsAtom } from '../../../Atoms/collections';
import { user } from '../../../Atoms/userAtoms';
import { logger } from '../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { createCollection, getCollections } from '../../../api/collections';
import { reloadClipsAtom, type VideoClip } from '../../../Atoms/clips';
import ModalTitle from '../../Titles/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/pro-regular-svg-icons';
import useSWR from 'swr';
import { useFetchCollections } from '../../../customHooks/collections/useGetCollections';
import { useCollections } from '../../../customHooks/useCollections';

const useStyles = createStyles((theme) => ({
	addButton: {
		height: 40,
		borderRadius: theme.radius.md,
		padding: '0 24px',
		backgroundColor: theme.fn.primaryColor(),
		color: theme.white,
		fontWeight: 500,
		'&:hover': {
			backgroundColor: theme.fn.primaryColor(),
		},
	},
}));

interface AddToCollectionModalProps {
	opened: boolean;
	close: () => void;
	clip: VideoClip;
	mutate?: (key: string, data?: any, shouldRevalidate?: boolean) => void;
}

export function AddToCollectionModal({
	opened,
	close,
	clip,
	mutate,
}: AddToCollectionModalProps) {
	const { classes } = useStyles();
	const focusTrapRef = useFocusTrap();
	const currentUser = useRecoilValue(user);
	const setReloadClips = useSetRecoilState(reloadClipsAtom);
	const { currentOrganizationID } = currentUser;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [selectedCollection, setSelectedCollection] = useState('');
	const { addContentToCollection } = useCollections(false);

	const {
		collections,
		setCollections,
		error: fetchError,
		isLoading,
	} = useFetchCollections(currentOrganizationID);

	useEffect(() => {
		if (fetchError) {
			logger('error', 'Error fetching collections', fetchError);
			showFailureNotification({
				message: 'Failed to fetch collections. Please try again.',
			});
		}
	}, [fetchError]);

	const handleAddToCollection = async () => {
		if (selectedCollection) {
			setLoading(true);
			try {
				if (collections.find((c) => c.id === selectedCollection)) {
					// Existing collection
					const res = await onAddToCollections(selectedCollection);
				} else {
					// New collection
					const res = await createCollection(
						selectedCollection,
						currentOrganizationID
					);
					const newCollection = res.data;
					setCollections((cur) => [...cur, newCollection]);
					await onAddToCollections(newCollection.id);
				}
				close();
			} catch (error) {
				logger('error', 'Error adding to collection', error);
				showFailureNotification({
					message: `Sorry, there was an error when adding to the collection. Please try again.`,
				});
			} finally {
				setLoading(false);
			}
		} else {
			setError('Please select or create a collection');
		}
	};

	const onAddToCollections = async (collectionId: string) => {
		if (clip) {
			try {
				const res = await addContentToCollection(collectionId, [clip.id]);
				showSuccessNotification({
					message: 'Clip added to collection successfully!',
				});
				if (mutate) {
					mutate(
						`/v1/meeting-video-clip/${clip.id}?userID=${currentUser.id}`,
						{
							...clip,
							collectionContents: [...clip.collectionContents, ...res],
						},
						false
					);
				} else {
					setReloadClips((prev) => !prev);
				}
			} catch (error) {
				console.error('Error adding clip to collection:', error);
				showFailureNotification({
					message: 'Failed to add clip to collection. Please try again.',
				});
			}
		}
	};

	useEffect(() => {
		if (!opened) {
			setSelectedCollection('');
			setError(null);
		}
	}, [opened]);

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={
				<ModalTitle
					text='Add to Collection'
					icon={<FontAwesomeIcon icon={faFolderPlus} size='xl' />}
				/>
			}
			size='md'
			radius='md'
			zIndex={10000}
		>
			{isLoading ? (
				<Center>
					<Loader />
					<Text size='sm'>Loading collections...</Text>
				</Center>
			) : error ? (
				<Text color='red'>{error}</Text>
			) : (
				<Stack ref={focusTrapRef} spacing='md'>
					{isLoading && <Text size='sm'>Loading collections...</Text>}
					<Select
						label='Select or create a collection'
						placeholder='Choose or create a collection'
						data={collections.map((c) => ({ value: c.id, label: c.name }))}
						value={selectedCollection}
						onChange={setSelectedCollection}
						searchable
						creatable
						getCreateLabel={(query) => `+ Create ${query}`}
						onCreate={(query) => {
							const item = { value: query, label: query };
							return item;
						}}
						disabled={isLoading}
					/>
					<Group position='right'>
						<Button
							variant='subtle'
							radius='md'
							onClick={close}
							px='sm'
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							onClick={handleAddToCollection}
							className={classes.addButton}
							loading={loading}
							leftIcon={<FontAwesomeIcon icon={faFolderPlus} />}
						>
							Add to Collection
						</Button>
					</Group>
				</Stack>
			)}
		</Modal>
	);
}
