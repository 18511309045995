import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { createStyles, Button, Tabs, Loader, Group } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoPlayer } from './components/video-player';
import { ClipInfo } from './components/clip-info';
import { ClipSummary } from './components/clip-summary';
import axiosInstance from '../../axios/axios';
import { currentUser, userOrganizationMembers } from '../../Atoms/userAtoms';
import { useRecoilValue } from 'recoil';
import { faShare } from '@fortawesome/pro-regular-svg-icons';
import { buildVideoClipsPlaybackUrl } from '../../helpers/clips';
import { useForm } from '@mantine/form';
import {
	INVITE_ONLY,
	ANYONE_WITH_LINK,
} from '../../helpers/settings/settings.constants';
import { changeMeetingSharing } from '../../helpers/meetings';
import { DateTime } from 'luxon';
import { ClipCardMenu } from '../ClipsPage/components/ClipCardMenu';
import { ShareClipModal } from '../meeting/components/MeetingDetailsTabSection/tabcontents/components/ShareClipModal';
import { EditClipModal } from '../meeting/components/MeetingDetailsTabSection/tabcontents/components/EditClipModal';
import { useDisclosure } from '@mantine/hooks';
import { organizationSettings } from '../../Atoms/settings';
import { teamDataAtom } from '../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import {
	addUsersToClip,
	generateClipSummary,
	removeUsersFromClip,
	updateVideoClip,
} from '../../api/clips';
import { logger } from '../../helpers/logger';
import {
	deleteCollectionContent,
	updateCollectionContent,
} from '../../api/collections';
import { AddToCollectionModal } from '../../components/Modals/CreateClipModals/AddToCollectionModal';
import { useCollections } from '../../customHooks/useCollections';
import { VideoClip } from '../../Atoms/clips';

const useStyles = createStyles((theme) => ({
	container: {
		minHeight: '100vh',
	},
	main: {
		maxWidth: '64rem',
		margin: '0 auto',
		padding: '1.5rem 1rem',
	},
	titleRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 600,
		color: theme.colors.gray[9],
	},
	videoWrapper: {
		marginBottom: '1.5rem',
	},
}));

const fetcher = (url: string) => axiosInstance.get(url).then((res) => res.data);

export function ClipPage() {
	const navigate = useNavigate();
	const { clipID } = useParams<{ clipID: string }>();
	const { classes } = useStyles();
	const user = useRecoilValue(currentUser);
	const orgUsers = useRecoilValue(userOrganizationMembers);
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);
	const [sharingOption, setSharingOption] = useState(INVITE_ONLY);
	const [userListWithOwnerFirst, setUserListWithOwnerFirst] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);
	const userID = user?.id;
	const teamData = useRecoilValue(teamDataAtom);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [shareModalOpened, { open: openShareModal, close: closeShareModal }] =
		useDisclosure(false);
	const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
		useDisclosure(false);
	const [isRegenerating, setIsRegenerating] = useState(false);
	const { sharing: sharingEnabled } = useRecoilValue(organizationSettings);
	const [addToCollectionModalOpened, setAddToCollectionModalOpened] =
		useState(false);
	const { addContentToCollection } = useCollections(false);

	const {
		data: clip,
		error,
		isLoading,
	} = useSWR<VideoClip>(
		userID ? `/v1/meeting-video-clip/${clipID}?userID=${userID}` : null,
		fetcher
	);

	const form = useForm({
		initialValues: {
			viewers: [],
		},
		validate: {
			viewers: (value) =>
				value.length === 0 ? 'Please add at least one viewer' : null,
		},
	});

	useEffect(() => {
		if (clip) {
			setSharingOption(
				clip.sharingToken?.active ? ANYONE_WITH_LINK : INVITE_ONLY
			);
			setUserListWithOwnerFirst(
				[...(clip.videoClipUserLinks || [])].sort((a, b) => {
					if (clip.owningUserID === a.userID) return -1;
					if (clip.owningUserID === b.userID) return 1;
					return (
						new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
					);
				})
			);
		}
	}, [clip]);

	const {
		id = '',
		organizationID = '',
		meetingID = '',
		owningUserID = '',
		title = '',
		startTime = 0,
		endTime = 0,
		thumbnailTime = 0,
		summary = '',
		createdAt = '',
		playbackURL = '',
		owningUser = {},
		meeting = { owningUser: {}, publishedAt: '', meetingDate: '' },
		videoClipUserLinks = [],
		collectionContents = [],
	} = clip || {};

	let name = '';
	let meetingOwningUser = { firstName: '', lastName: '' };
	let publishedAt = '';
	let meetingDate = '';

	if ('name' in meeting) {
		name = meeting.name;
		meetingOwningUser = {
			firstName: meeting.owningUser?.firstName || '',
			lastName: meeting.owningUser?.lastName || '',
		};
		publishedAt = meeting.publishedAt;
		meetingDate = meeting.meetingDate;
	}
	const meetingHost = `${meetingOwningUser?.firstName || ''} ${
		meetingOwningUser?.lastName || ''
	}`.trim();

	const formattedMeetingDate = meetingDate
		? DateTime.fromMillis(+meetingDate).toFormat('MM/dd/yyyy')
		: publishedAt
		? DateTime.fromMillis(+publishedAt).toFormat('MM/dd/yyyy')
		: 'N/A';
	const avatarUrl = clip?.owningUser?.userAvatarURL || '';

	const playbackUrlWithTimestamps = buildVideoClipsPlaybackUrl(
		playbackURL,
		startTime,
		endTime
	);
	const isOwner = owningUserID === userID;

	const handleShare = () => {
		openShareModal();
	};

	const clipUrl = `${window.location.origin}/clip/${clipID}`; // Adjust this based on your actual URL structure

	const regenerateSummary = useCallback(
		async (clipId: string) => {
			setIsRegenerating(true);
			try {
				const updatedClip = await generateClipSummary(clipId, organizationID);
				mutate(
					`/v1/meeting-video-clip/${clipID}?userID=${userID}`,
					updatedClip,
					false
				);
				showSuccessNotification({
					message: 'Summary regenerated successfully.',
				});
			} catch (error) {
				logger('error', 'Error regenerating summary', error);
				showFailureNotification({
					message: 'Failed to regenerate summary. Please try again.',
				});
			} finally {
				setIsRegenerating(false);
			}
		},
		[organizationID, clipID, userID]
	);

	const handleSaveClip = useCallback(
		async ({
			title,
			summary,
			collectionIDs,
		}: {
			title: string;
			summary: string;
			collectionIDs: string[];
		}) => {
			try {
				// Update clip
				const savedClip = await updateVideoClip(
					clip?.id,
					{
						title,
						summary,
					},
					organizationID
				);

				// Update collections
				const collectionsToAdd = collectionIDs.filter(
					(id) =>
						!collectionContents.some(({ collectionID }) => collectionID === id)
				);
				const collectionsToRemove = collectionContents.filter(
					({ collectionID }) => !collectionIDs.includes(collectionID)
				);

				const promises = await Promise.all([
					...collectionsToAdd.map((collectionID) =>
						updateCollectionContent(collectionID, [{ targetID: clip.id }])
					),
					...collectionsToRemove.map(({ collectionID }) =>
						deleteCollectionContent(collectionID, clip.id)
					),
				]);

				const newCollectionContents = Array.isArray(promises[0].data?.data)
					? promises[0].data.data
					: [];
				const removedTargetIDs = promises
					.slice(1)
					.map((promise) => promise?.id);

				// need to update collectionContents on the clip
				const updatedClip = {
					...clip,
					...savedClip,
					collectionContents: [
						...collectionContents.filter(
							({ targetID }) => !removedTargetIDs.includes(targetID)
						),
						...newCollectionContents,
					],
				};

				mutate(
					`/v1/meeting-video-clip/${clipID}?userID=${userID}`,
					updatedClip,
					false
				);
				showSuccessNotification({ message: 'Clip updated successfully.' });
				closeEditModal();
			} catch (error) {
				logger('error', 'Error updating clip', error);
				showFailureNotification({
					message: 'Failed to update clip. Please try again.',
				});
			}
		},
		[organizationID, clipID, userID, clip, collectionContents]
	);

	const handleChangeSharingOption = useCallback(
		async (value: string) => {
			try {
				const newSharingToken = await changeMeetingSharing(
					value,
					clip.id,
					organizationID
				);
				const updatedClip = {
					...clip,
					sharingToken: newSharingToken,
				};
				mutate(
					`/v1/meeting-video-clip/${clipID}?userID=${userID}`,
					updatedClip,
					false
				);
			} catch (err) {
				logger('error', 'Error changing sharing option', err);
				showFailureNotification({
					message: 'Failed to change sharing option. Please try again.',
				});
			}
		},
		[clip, organizationID, clipID, userID]
	);

	const handleRemoveUser = useCallback(
		async (userId: string) => {
			try {
				const { removedUserLinks } = await removeUsersFromClip(
					[userId],
					clip.id,
					organizationID
				);
				const updatedClip = {
					...clip,
					videoClipUserLinks: videoClipUserLinks.filter(
						(link) => !removedUserLinks.includes(link.userID)
					),
				};
				mutate(
					`/v1/meeting-video-clip/${clipID}?userID=${userID}`,
					updatedClip,
					false
				);
				showSuccessNotification({ message: 'User removed successfully.' });
			} catch (error) {
				logger('error', 'Error removing user from clip', error);
				showFailureNotification({
					message: 'Failed to remove user. Please try again.',
				});
			}
		},
		[clip, organizationID, clipID, userID, videoClipUserLinks]
	);

	const handleAddUsers = useCallback(async () => {
		if (form.values.viewers.length > 0) {
			setButtonLoading(true);
			try {
				const { videoClipUserLinks: newVideoClipUserLinks } =
					await addUsersToClip({
						videoClipID: clip.id,
						emails: form.values.viewers,
						organizationID,
					});

				const invitedUserLinks = newVideoClipUserLinks?.data;
				if (!Array.isArray(invitedUserLinks)) {
					showFailureNotification({
						message: newVideoClipUserLinks?.message,
					});
					return;
				}

				const updatedClip = {
					...clip,
					videoClipUserLinks: [
						...videoClipUserLinks,
						...invitedUserLinks.map((link) => ({
							...link,
							firstName: link.user.firstName,
							lastName: link.user.lastName,
							email: link.user.email,
							userID: link.user.id,
							avatarImageURL: link.user.avatarImageURL,
						})),
					],
				};

				mutate(
					`/v1/meeting-video-clip/${clipID}?userID=${userID}`,
					updatedClip,
					false
				);
				form.reset();
				showSuccessNotification({ message: 'Users added successfully.' });
			} catch (err) {
				logger('error', 'Error adding users to clip', err);
				showFailureNotification({
					message: 'Failed to add users. Please try again.',
				});
			} finally {
				setButtonLoading(false);
			}
		}
	}, [clip, form, organizationID, clipID, userID, videoClipUserLinks]);

	const handleAddToCollection = async () => {
		setAddToCollectionModalOpened(true);
	};

	const handleShareClip = useCallback(
		(clip) => {
			openShareModal();
		},
		[openShareModal]
	);

	const handleEditClip = useCallback(
		(clip) => {
			openEditModal();
		},
		[openEditModal]
	);

	const onCopyLink = (clip) => {
		navigator.clipboard.writeText(`${window.location.origin}/clip/${clip.id}`);
		const url = new URL(`${window.location.origin}/clip/${clip.id}`);
		if (clip?.sharingToken?.active) {
			url.searchParams.append('vt', clip.sharingToken.token);
		}
		navigator.clipboard.writeText(url.toString());
		showSuccessNotification({ message: 'Link copied to clipboard.' });
	};

	if (error) return <div>Failed to load clip data</div>;
	if (isLoading) return <Loader />;

	return (
		<div className={classes.container}>
			<main className={classes.main}>
				<div className={classes.titleRow}>
					<h1 className={classes.title}>{title}</h1>
					<Group noWrap spacing={'xs'}>
						{isOwner ? (
							<Button onClick={handleShare} radius={'md'}>
								Share
							</Button>
						) : null}

						<ClipCardMenu
							size='xl'
							clip={clip}
							handleAddToCollection={handleAddToCollection}
							onCopyLink={onCopyLink}
							onEdit={handleEditClip}
							onManageAccess={handleShareClip}
						/>
					</Group>
				</div>
				<div className={classes.videoWrapper}>
					<VideoPlayer src={playbackUrlWithTimestamps} />
				</div>

				<Tabs defaultValue='meeting-info'>
					<Tabs.List>
						<Tabs.Tab value='meeting-info'>Meeting Info</Tabs.Tab>
						<Tabs.Tab value='clip-description'>Clip Description</Tabs.Tab>
						<Button
							onClick={() => navigate(`/meetings/${clip?.meetingID}`)}
							ml={'auto'}
							variant='transparent'
							c={'primary-cta'}
						>
							View full meeting
						</Button>
					</Tabs.List>

					<Tabs.Panel value='meeting-info'>
						<ClipInfo
							title={title}
							creator={`${clip?.owningUser?.firstName} ${clip?.owningUser?.lastName}`}
							host={meetingHost}
							meetingTitle={name}
							avatarUrl={avatarUrl}
							date={formattedMeetingDate}
						/>
					</Tabs.Panel>

					<Tabs.Panel value='clip-description'>
						<ClipSummary summary={summary} />
					</Tabs.Panel>
				</Tabs>

				<ShareClipModal
					opened={shareModalOpened}
					onClose={closeShareModal}
					selectedClip={clip}
					handleRemoveUser={handleRemoveUser}
					organizer={meeting?.owningUser}
					sharingEnabled={sharingEnabled}
					handleChangeSharingOption={handleChangeSharingOption}
					handleAddUsers={handleAddUsers}
					buttonLoading={buttonLoading}
					form={form}
					isClipOwner={clip?.owningUserID === user.id}
				/>

				<EditClipModal
					opened={editModalOpened}
					onClose={closeEditModal}
					selectedClip={clip}
					currentMeetingData={meeting}
					videoRef={videoRef}
					isRegenerating={isRegenerating}
					regenerateSummary={regenerateSummary}
					onSave={handleSaveClip}
				/>

				<AddToCollectionModal
					opened={addToCollectionModalOpened}
					close={() => setAddToCollectionModalOpened(false)}
					clip={clip}
					mutate={mutate}
				/>
			</main>
		</div>
	);
}
