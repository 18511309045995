import type React from 'react';
import { useEffect, useState } from 'react';
import {
	Modal,
	Group,
	Stack,
	Text,
	Button,
	Tooltip,
	Textarea,
	Paper,
	TextInput,
	MultiSelect,
	Loader,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faFolders } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from '@mantine/form';
import ReelayVideoPlayer from '../../../ReelayVideoPlayer';
import ModalTitle from '../../../../../../components/Titles/ModalTitle';
import { buildVideoClipsPlaybackUrl } from '../../../../../../helpers/clips';
import { useRecoilValue } from 'recoil';
import {
	collectionsAtom,
	collectionsHashMapAtom,
} from '../../../../../../Atoms/collections';
import { VideoClip } from '../../../../../../Atoms/clips';

interface EditClipModalProps {
	opened: boolean;
	onClose: () => void;
	selectedClip: VideoClip;
	currentMeetingData: any;
	videoRef: React.RefObject<HTMLVideoElement>;
	isRegenerating: boolean;
	regenerateSummary: (clipId: string) => void;
	onSave: (values: any) => void;
}

export function EditClipModal({
	opened,
	onClose,
	selectedClip,
	currentMeetingData,
	videoRef,
	isRegenerating,
	regenerateSummary,
	onSave,
}: EditClipModalProps) {
	if (!selectedClip) return null;

	const collections = useRecoilValue(collectionsAtom);
	const collectionsHashMap = useRecoilValue(collectionsHashMapAtom);
	const [value, setValue] = useState([]);
	const collectionsData = collections.map((collection) => ({
		...collection,
		label: collection.name,
		value: collection.id,
		key: collection.id,
	}));

	const { id, title, summary, playbackURL, startTime, endTime } = selectedClip;

	const clipForm = useForm({
		initialValues: {
			title: '',
			summary: '',
		},
	});

	useEffect(() => {
		const existingCollections = (selectedClip.collectionContents || []).map(
			({ collectionID }) => {
				const collection = collectionsHashMap[collectionID];
				return collection?.id;
			}
		);
		setValue(existingCollections);
		clipForm.setValues({
			title: selectedClip.title || '',
			summary: selectedClip.summary || '',
		});
	}, [selectedClip, collectionsHashMap]);

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			size='lg'
			title={<ModalTitle text='Edit Clip' />}
		>
			<Stack spacing='md'>
				<div style={{ width: '100%', aspectRatio: '16/9' }}>
					<ReelayVideoPlayer
						meeting={currentMeetingData}
						playbackURL={buildVideoClipsPlaybackUrl(
							playbackURL,
							startTime,
							endTime
						)}
						playerRef={videoRef}
					/>
				</div>
				<Paper>
					<Stack spacing='md'>
						<Text size='sm' fw={600}>
							Title
						</Text>
						<TextInput
							{...clipForm.getInputProps('title')}
							placeholder='Enter clip title'
						/>

						<Text size='sm' fw={600}>
							Add to collection
						</Text>
						<MultiSelect
							value={value}
							defaultValue={value}
							onChange={setValue}
							data={collectionsData}
							placeholder='Select collections'
							searchable
							clearable
							clearButtonProps={{ 'aria-label': 'Clear selection' }}
							nothingFound='No collections found'
							variant='filled'
							size='sm'
							icon={<FontAwesomeIcon icon={faFolders} />}
						/>

						<Group position='apart' align='center'>
							<Text size='sm' fw={600}>
								Summary
							</Text>
							<Tooltip label='Regenerate Summary' position='top'>
								<Button
									p={0}
									variant='transparent'
									size='sm'
									onClick={() => regenerateSummary(id)}
									loading={isRegenerating}
								>
									<FontAwesomeIcon icon={faRotateRight} />
								</Button>
							</Tooltip>
						</Group>
						<Textarea
							{...clipForm.getInputProps('summary')}
							autosize
							minRows={6}
							maxRows={6}
						/>
					</Stack>
				</Paper>
				<Button
					onClick={() =>
						onSave({
							...clipForm.values,
							collectionIDs: value,
						})
					}
				>
					Save
				</Button>
			</Stack>
		</Modal>
	);
}
