import { useState, useRef, useEffect } from 'react';
import {
	Modal,
	Button,
	Text,
	Stack,
	createStyles,
	Group,
	Divider,
	Select,
	RangeSlider,
	Box,
	Checkbox,
	Textarea,
	ActionIcon,
	Tooltip,
	TextInput,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faScissors,
	faUser,
	faImage,
	faPaperPlaneTop,
	faInfoCircle,
	faArrowLeft,
	faArrowRight,
	faPipe,
} from '@fortawesome/pro-regular-svg-icons';
import { useForm } from '@mantine/form';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { teamDataAtom } from '../../../Atoms/userAtoms';
import { addUsersToClip, createVideoClip } from '../../../api/clips';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import ModalTitle from '../../Titles/ModalTitle';
import { logger } from '../../../helpers/logger';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import {
	currentMeeting,
	currentMeetingDistributionList,
	meetingClipsAtom,
} from '../../../Atoms/meetingAtom';
import { MILLISECONDS_PER_SECOND } from '../../constants';
import ReelayVideoPlayer from '../../../pages/meeting/components/ReelayVideoPlayer';
import {
	INVITE_ONLY,
	ANYONE_WITH_LINK,
} from '../../../helpers/settings/settings.constants';
import { DateInput } from '@mantine/dates';
import CustomCalendarDay from '../../CustomCalendarDay';
import { flattenValidateAndFilterInvitees } from '../../../helpers/teams';
import { organizationSettings } from '../../../Atoms/settings';
import { changeMeetingSharing } from '../../../helpers/meetings';
import { buildVideoClipsPlaybackUrl } from '../../../helpers/clips';
import { ShareWithOthersModal } from './ShareWithOthersModal';
import { AddToCollectionModal } from './AddToCollectionModal';
import { VideoClip } from '../../../Atoms/clips';
import { useCollections } from '../../../customHooks/useCollections';

const useStyles = createStyles((theme) => ({
	preview: {
		width: '100%',
		aspectRatio: '16/9',
		backgroundColor: theme.colors.dark[7],
		borderRadius: theme.radius.md,
		overflow: 'hidden',
	},
	video: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
	},
	createButton: {
		height: 40,
		borderRadius: theme.radius.md,
		padding: '0 24px',
		backgroundColor: theme.fn.primaryColor(),
		color: theme.white,
		fontWeight: 500,
		'&:hover': {
			backgroundColor: theme.fn.primaryColor(),
		},
	},
	divider: {
		borderTopColor: theme.colors['background-gray-2'][3],
		margin: `${theme.spacing.md}px 0`,
	},
	timeLabel: {
		position: 'absolute',
		top: '18%',
		transform: 'translateY(-50%)',
		color: theme.colors['secondary-text'][0],
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,
	},
	startTimeLabel: {
		left: '3%',
	},
	endTimeLabel: {
		right: '3%',
	},
	thumbnailIcon: {
		// styles remain unchanged
	},
	dateInput: {
		borderRadius: '8px',
		fontSize: '12px',
		width: '120px',
		height: '40px',
	},
}));

interface CreateClipModalProps {
	opened: boolean;
	onClose: () => void;
	videoUrl: string;
	meetingID: string;
}

export function CreateClipModal({
	opened,
	onClose,
	videoUrl,
	meetingID,
}: CreateClipModalProps) {
	const { classes, theme } = useStyles();
	const videoRef = useRef<HTMLVideoElement>(null);
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const setClips = useSetRecoilState(meetingClipsAtom);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [shareChecked, setShareChecked] = useState(false);
	const [addToCollectionChecked, setAddToCollectionChecked] = useState(false);
	// const [message, setMessage] = useState('');
	const [thumbnailTime, setThumbnailTime] = useState(0);
	const [sharingOption, setSharingOption] = useState(INVITE_ONLY);
	const { videoMetadata, organizationID } = meeting;
	const { duration, playbackURL } = videoMetadata;
	const durationInSeconds = Math.floor(duration / MILLISECONDS_PER_SECOND);
	const [timeRange, setTimeRange] = useState<[number, number]>([
		0,
		durationInSeconds,
	]);

	const [shareModalOpened, setShareModalOpened] = useState(false);
	const [addToCollectionModalOpened, setAddToCollectionModalOpened] =
		useState(false);
	const [modalsQueue, setModalsQueue] = useState<string[]>([]);
	const [newClip, setNewClip] = useState<VideoClip | null>(null);
	const { addContentToCollection } = useCollections(false);

	const form = useForm({
		initialValues: {
			title: '',
			// viewers: [],
		},
		validate: {
			title: (value) => (value ? null : 'Title is required.'),
			// viewers: (value) =>
			// 	value.length ? null : 'At least one viewer is required.',
		},
	});

	useEffect(() => {
		if (videoRef.current) {
			const handleLoadedMetadata = () => {
				const duration = videoRef.current?.duration;
				setTimeRange([0, duration]);
			};
			videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
			return () => {
				videoRef.current?.removeEventListener(
					'loadedmetadata',
					handleLoadedMetadata
				);
			};
		}
	}, []);

	useEffect(() => {
		if (!opened) {
			setTimeRange([0, durationInSeconds]);
			form.reset();
			setShareChecked(false);
			setAddToCollectionChecked(false);
			setThumbnailTime(0);
			setSharingOption(INVITE_ONLY);
		}
	}, [opened]);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.currentTime = 0;
		}
	}, [timeRange]);

	useEffect(() => {
		if (modalsQueue.length) {
			const firstItem = modalsQueue[0];
			if (firstItem === 'share') {
				setShareModalOpened(true);
			} else if (firstItem === 'collection') {
				setAddToCollectionModalOpened(true);
			}
		}
	}, [modalsQueue]);

	useEffect(() => {
		if (
			!shareModalOpened &&
			!addToCollectionModalOpened &&
			modalsQueue.length
		) {
			setModalsQueue((queue) => queue.slice(1));
		}
	}, [shareModalOpened, addToCollectionModalOpened]);

	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = Math.floor(seconds % 60);
		return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
	};

	const handleRangeChange = (values: [number, number]) => {
		setTimeRange(values);
		// if (videoRef.current) {
		// 	console.log('setting currentTime', values[0]);
		// 	videoRef.current.currentTime = values[0];
		// }
	};

	const handleCreateVideoClip = async () => {
		try {
			setButtonLoading(true);
			// const emails = flattenValidateAndFilterInvitees(form.values.viewers);

			const clipData = await createVideoClip(
				{
					meetingID,
					startTime: timeRange[0],
					endTime: timeRange[1],
					thumbnailTime,
					title: form.values.title,
				},
				organizationID
			);

			setClips((clips) => [...clips, clipData]);
			const optionalModals = [];
			if (shareChecked) {
				optionalModals.push('share');
			}
			if (addToCollectionChecked) {
				optionalModals.push('collection');
			}
			setModalsQueue(optionalModals);
			setNewClip(clipData);
			showSuccessNotification({
				title: 'Success!',
				message: 'Video clip created.',
			});
			form.reset();
			// setNotifyPeopleChecked(false);
			onClose();
		} catch (error) {
			showFailureNotification({ message: 'Failed to share video clip' });
			logger('error', 'Failed to share video clip', error);
		} finally {
			setButtonLoading(false);
		}
	};

	const finalPlaybackURL = buildVideoClipsPlaybackUrl(
		playbackURL,
		timeRange[0],
		timeRange[1]
	);

	const handleSetThumbnail = () => {
		if (videoRef.current) {
			setThumbnailTime(Math.round(videoRef.current.currentTime + timeRange[0]));
			showSuccessNotification({
				message: 'Thumbnail set.',
			});
		}
	};

	const onShare = async ({ emails, message, sharingOption }) => {
		try {
			const { videoClipUserLinks } = await addUsersToClip({
				emails,
				videoClipID: newClip.id,
				organizationID,
				message,
				sharingOption,
			});

			const invitedUserLinks = videoClipUserLinks?.data;

			if (!Array.isArray(invitedUserLinks)) {
				showFailureNotification({
					message: videoClipUserLinks?.message,
				});
				return;
			}
			setNewClip((prevClip) => ({
				...prevClip,
				videoClipUserLinks: invitedUserLinks.map((link) => ({
					...link,
					firstName: link.user.firstName,
					lastName: link.user.lastName,
					email: link.user.email,
					userID: link.user.id,
					avatarImageURL: link.user.avatarImageURL,
				})),
			}));
			showSuccessNotification({
				title: 'Success!',
				message: 'Video clip shared successfully',
			});
		} catch (error) {
			console.log('onShare error', error);
			showFailureNotification({
				message: 'Failed to share video clip',
			});
			logger('error', 'Failed to share video clip', error);
		}
	};

	return (
		<>
			<Modal
				opened={opened}
				onClose={onClose}
				title={
					<ModalTitle
						text='Create a Clip'
						icon={<FontAwesomeIcon icon={faScissors} size='xl' />}
					/>
				}
				size='lg'
				radius='md'
				zIndex={10000}
				styles={(theme) => ({
					header: {
						borderRadius: theme.spacing.lg,
					},
					close: {
						color: theme.colors['background-gray-2'][6],
					},
				})}
			>
				<Stack spacing='md'>
					{/* <Divider className={classes.divider} /> */}
					{/* <Text size='sm'>
						Recipient will receive a video clip that you trim.
					</Text>
					<Text size='sm'>
						If they are not a Reelay customer, they may be required to send you
						a request to access this recording.
					</Text> */}

					<Divider className={classes.divider} />

					<Text weight={500}>
						Drag time boxes to select times to trim your video:
					</Text>

					<Box sx={{ position: 'relative', marginTop: '2.75rem' }}>
						<RangeSlider
							min={0}
							max={durationInSeconds}
							step={1}
							value={timeRange}
							onChange={handleRangeChange}
							// labelAlwaysOn
							label={null}
							thumbChildren={[
								<FontAwesomeIcon
									key={1}
									icon={faPipe}
									size='lg'
									style={{ color: 'white' }}
								/>,
								<FontAwesomeIcon
									key={2}
									icon={faPipe}
									size='lg'
									style={{ color: 'white' }}
								/>,
							]}
							styles={(theme) => ({
								root: {
									height: '48px',
								},
								track: {
									backgroundColor: theme.colors['background-gray-2'][3],
									height: '48px',
									'&::before': {
										borderRadius: 0,
										right: 0,
										left: 0,
									},
								},
								bar: {
									backgroundColor: theme.fn.primaryColor(),
									opacity: 0.3,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: 0,
								},
								thumb: {
									cursor: 'ew-resize',
									width: '16px',
									height: '60px',
									backgroundColor: theme.fn.primaryColor(),
									border: 'none',
									boxShadow: 'none',
									'&::before': {
										display: 'none',
									},
								},
							})}
						/>
						<Text fz={'sm'} weight={400} c={'secondary-text'} align='center'>
							{formatTime(timeRange[0])} - {formatTime(timeRange[1])}
						</Text>
						{/* <Text className={`${classes.timeLabel} ${classes.startTimeLabel}`}>
						{formatTime(0)}
					</Text>
					<Text className={`${classes.timeLabel} ${classes.endTimeLabel}`}>
						{formatTime(durationInSeconds)}
					</Text> */}
					</Box>

					<Text weight={600} size='sm'>
						Preview your trimmed video clip:
					</Text>

					<div className={classes.preview}>
						<ReelayVideoPlayer
							meeting={meeting}
							playbackURL={finalPlaybackURL}
							playerRef={videoRef}
						/>
					</div>

					<form
						onSubmit={(e) => {
							e.preventDefault();
							if (form.validate().hasErrors) return;
							handleCreateVideoClip();
						}}
					>
						<Stack spacing='md'>
							<TextInput
								required
								label='Clip Title'
								placeholder='Enter a brief description of your video clip'
								{...form.getInputProps('title')}
							/>

							<Group spacing='sm' noWrap position='apart'>
								<Group spacing={'sm'} noWrap>
									<Checkbox
										checked={shareChecked}
										onChange={(event) =>
											setShareChecked(event.currentTarget.checked)
										}
										label='Share with others'
									/>
									<Checkbox
										checked={addToCollectionChecked}
										onChange={(event) =>
											setAddToCollectionChecked(event.currentTarget.checked)
										}
										label='Add to collection'
									/>
								</Group>

								<Group spacing={0} noWrap>
									<Tooltip
										label='To set a custom thumbnail for this clip, change the video player time to the desired thumbnail time and click the thumbnail icon to the right.'
										position='top'
									>
										<ActionIcon
											variant='transparent'
											size='lg'
											className={classes.thumbnailIcon}
										>
											<FontAwesomeIcon
												icon={faInfoCircle}
												style={{
													color: theme.colors['primary-cta'][6],
												}}
											/>
										</ActionIcon>
									</Tooltip>
									<Tooltip label='Set current time as thumbnail' position='top'>
										<ActionIcon
											variant='transparent'
											onClick={handleSetThumbnail}
											size='lg'
											className={classes.thumbnailIcon}
										>
											<FontAwesomeIcon icon={faImage} />
										</ActionIcon>
									</Tooltip>
								</Group>
							</Group>

							<Group position='right'>
								<Button
									type='submit'
									className={classes.createButton}
									loading={buttonLoading}
									leftIcon={<FontAwesomeIcon icon={faScissors} />}
								>
									Create
								</Button>
							</Group>
						</Stack>
					</form>
				</Stack>
			</Modal>
			<ShareWithOthersModal
				opened={shareModalOpened}
				close={() => setShareModalOpened(false)}
				onShare={onShare}
			/>
			<AddToCollectionModal
				opened={addToCollectionModalOpened}
				close={() => setAddToCollectionModalOpened(false)}
				clip={newClip}
			/>
		</>
	);
}
