import React, { useRef } from 'react';
import { Button, createStyles, Grid, Overlay, Stack } from '@mantine/core';
import SkeletonCard from '../../../components/Skeletons/SkeletonCard';
import useMediaQueries from '../../../customHooks/useMediaQueries';

const useStyles = createStyles((theme) => ({
	createReelay: {
		border: theme.colors?.primary[0],
		fontSize: '16px',
		color: theme.colors?.['white'],
		lineHeight: '19px',
		width: '208px',
		height: '40px',
	},
}));

interface Props {
	segmentValue: string;
	isClips?: boolean;
}

export default function Introduction({ segmentValue, isClips = false }: Props) {
	const stackRef = useRef<HTMLDivElement>(null);
	const { widthLessThanXL } = useMediaQueries();

	const skeletonCardArray = Array.from(
		{ length: widthLessThanXL ? 6 : 8 },
		(_, index) => index
	);

	const getButtonText = (value: string) => {
		if (isClips) {
			return 'Future home of your Reelay clips';
		}
		switch (value) {
			case 'live':
				return 'Future home of your Reelay recordings';
			case 'all':
				return 'No meetings currently in all';
			case 'expired':
				return 'No meetings currently in expired';
			case 'review':
				return 'No meetings currently in review';
			case 'upcoming':
				return 'No meetings currently in upcoming';
			default:
				return 'Future home of your Reelay recordings';
		}
	};

	return (
		<Stack ref={stackRef} w={'100%'} spacing={'xl'}>
			<Grid gutterSm={32} gutterXl={32} h={'100%'} p={'xl'}>
				{skeletonCardArray.map((idx) => (
					<SkeletonCard key={idx} animate={false} />
				))}
			</Grid>
			<Overlay
				blur={4}
				radius={'md'}
				color='#F9FAFB'
				p={'xl'}
				center
				h={stackRef?.current?.clientHeight || '100%'}
				zIndex={10}
			>
				<Button
					color='secondary-text'
					variant='transparent'
					radius='xl'
					size='xl'
					h={60}
					px={'xl'}
					styles={(theme) => ({
						label: {
							fontWeight: 400,
							color: theme.colors['secondary-text'][0],
							fontSize: '2.25rem',
						},
					})}
				>
					{getButtonText(segmentValue)}
				</Button>
			</Overlay>
		</Stack>
	);
}
