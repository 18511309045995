import { DateTime } from 'luxon';

export const getYearFromDate = (dateString) => {
	// Parse the date string into a Luxon DateTime object
	const dateTime = DateTime.fromISO(dateString);
	// Get the year from the DateTime object
	const year = dateTime.year;
	return year;
};

export const getMonthAndDayFromDate = (dateString) => {
	// Parse the date string into a Luxon DateTime object
	const dateTime = DateTime.fromISO(dateString);
	// Format the date as "MMM. DD"
	const formattedDate = dateTime.toFormat('MMM. dd');
	return formattedDate;
};

export const getNormalDatefromISOString = (dateString) => {
	// Parse the date string into a Luxon DateTime object
	const dateTime = DateTime.fromISO(dateString);
	// Format the date as "MMM. DD"
	const formattedDate = dateTime.toFormat('MM-dd-yyyy');
	return formattedDate;
};

export const handleFormatTime = (date: Date, time: string) => {
	const luxonDate = DateTime.fromJSDate(date);

	// Parse the provided 24-hour time string
	const [hours, minutes] = time.split(':').map(Number);

	// Set the time part of the Luxon DateTime
	const combinedDateTime = luxonDate.set({
		hour: hours,
		minute: minutes,
		second: 0,
		millisecond: 0,
	});

	const combinedDateTimeISOString = combinedDateTime.toISO();
	return combinedDateTimeISOString;
};

export const combineDateTime = (date: Date, time: string) => {
	const luxonDate = DateTime.fromJSDate(date);

	// Parse the provided 24-hour time string
	const [hours, minutes] = time.split(':').map(Number);

	// Set the time part of the Luxon DateTime
	return luxonDate.set({
		hour: hours,
		minute: minutes,
		second: 0,
		millisecond: 0,
	});
};

export function convertDateFormat(dateStr) {
	try {
		const [month, day, year] = dateStr.split('-').map(Number);

		if (isNaN(month) || isNaN(day) || isNaN(year)) {
			throw new Error('Invalid date format');
		}

		const originalDate = new Date(year, month - 1, day); // Month is 0-based
		const formattedDate = `${originalDate.getFullYear()}-${(
			originalDate.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;

		return formattedDate;
	} catch (error) {
		return null; // Return null for invalid date formats
	}
}

export const parseDateTime = (dateString: string | undefined) => {
	if (!dateString) return null;

	// Check if the dateString is a valid ISO string
	const isoDate = DateTime.fromISO(dateString);
	if (isoDate.isValid) {
		return isoDate;
	}

	// Check if the dateString is a valid timestamp
	const timestamp = parseInt(dateString, 10);
	if (!isNaN(timestamp)) {
		const timestampDate = DateTime.fromMillis(timestamp);
		if (timestampDate.isValid) {
			return timestampDate;
		}
	}

	return null;
};

export const formatDeadlineTime = (input: string | number) => {
	let dateTime;

	if (typeof input === 'string') {
		// Check if the string is a valid ISO date
		dateTime = DateTime.fromISO(input);
		if (!dateTime.isValid) {
			// If not a valid ISO date, try to parse it as a number
			const timestamp = parseInt(input, 10);
			if (!isNaN(timestamp)) {
				dateTime = DateTime.fromMillis(timestamp);
			}
		}
	} else if (typeof input === 'number') {
		dateTime = DateTime.fromMillis(input);
	}

	if (!dateTime || !dateTime.isValid) {
		throw new Error('Invalid date input');
	}

	return dateTime.toLocaleString(DateTime.DATETIME_MED);
};

export const getFullDateFromEpochMS = (
	renewalDate: number,
	userTimeZone?: string
): string => {
	if (!renewalDate) return '';
	let timeZone = 'UTC'; // Default to UTC if no valid timezone is found

	// Check if Intl is available and get the browser's timezone
	if (typeof Intl !== 'undefined' && Intl.DateTimeFormat) {
		timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	// Validate userTimeZone if provided
	if (userTimeZone) {
		try {
			const testDate = DateTime.now().setZone(userTimeZone);
			if (testDate.isValid) {
				timeZone = userTimeZone;
			}
		} catch (error) {
			console.warn('Invalid userTimeZone provided:', userTimeZone);
		}
	}

	return DateTime.fromMillis(renewalDate, { zone: timeZone }).toLocaleString(
		DateTime.DATE_FULL
	);
};

export const formatCurrency = (amount: number) =>
	!amount
		? 'N/A'
		: amount.toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
		  });

export const isDateYearCurrentYear = (date: string) => {
	// Replace isoDate with your ISO date
	const dateTime = DateTime.fromISO(date);

	// Get the current year
	const currentYear = DateTime.local().year;

	// Get the year from the dateTime object
	const yearFromISODate = dateTime.year;

	// Compare if they are the same
	return currentYear === yearFromISODate;
};
